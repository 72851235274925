/* ::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-track {
  background-color: transparent;
} */

* {
  font-family: "Poppins", sans-serif;
}
